import institutionMangement from './../../assets/img/products/college-management.jpg';
import ecommerceWebsite from './../../assets/img/products/ecommerce.jpg';
// import cllipingPath from './../../assets/img/products/clipping-path.png';
import grandeurae from './../../assets/img/products/grandeurae.png';
import outss from './../../assets/img/products/outss.png';
import photobookEditor from './../../assets/img/products/photobook-editor.png';
import projectManagement from './../../assets/img/products/project-management.png';
// import removeBg from './../../assets/img/products/remove-bg.png';
import WSIN from './../../assets/img/products/WSIN.png';
// import vLoot from './../../assets/img/products/v-loot.png';
import banking from './../../assets/img/products/banking-api.png';

export const productsData = [


    {
        id: 1,
        title: "Outss",
        img: `${outss}`,
        url: 'https://outss.co',
    },
    {
        id: 2,
        title: "Ecommerce Website",
        img: `${ecommerceWebsite}`,
        url: 'https://ecommerce.techvaultltd.com/',
    },
    {
        id: 3,
        title: "College Management",
        img: `${institutionMangement}`,
        url: 'https://institutionamanagement.techvaultltd.com/',
    },
    {
        id: 4,
        title: "Photobook Editor",
        img: `${photobookEditor}`,
        url: '',
    },

    {
        id: 5,
        title: "Grandeurae",
        img: `${grandeurae}`,
        url: 'https://grandeurae.com',
    },
    {
        id: 6,
        title: "WSIN",
        img: `${WSIN}`,
        url: '',
    },
    // {
    //     id: 7,
    //     title: "Remove Background",
    //     img: `${removeBg}`,
    //     url: '',
    // },
    // {
    //     id: 8,
    //     title: "Clipping Path",
    //     img: `${cllipingPath}`,
    //     url: '',
    // },
    {
        id: 9,
        title: "Project Management ",
        img: `${projectManagement}`,
        url: '',
    },

    // {
    //     id: 10,
    //     title: "V-Loot",
    //     img: `${vLoot}`,
    //     url: "",
    // },

    {
        id: 11,
        title: "Banking API Service ",
        img: `${banking}`,
        url: '',
    },
]