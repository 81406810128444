import { Route, Routes } from 'react-router-dom';
import Error from './pages/Error/Error';
import Home from './pages/HomePage/Home';
import ServicesPage from './pages/ServicesPage/ServicesPage';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import AboutPage from './pages/AboutPage/AboutPage';
import TeamPage from './pages/TeamPage/TeamPage';
import ContactPage from './pages/ContactPage/ContactPage';
import ClientsPage from './pages/ClientsPage/ClientsPage';


// import animate css for animation
import "animate.css/animate.css";

function App() {
  return (
    <Routes>
      <Route path="*" element={<Error />} />
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/projects" element={<ProductsPage />} />
      <Route path="/clients-portfolio" element={<ClientsPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  );
}

export default App;
