// our clients 
import Organization from './../../assets/img/clients/Organization.jpg';
import Outss_files from './../../assets/img/clients/Outss_files-02.jpg';
import STYLE_BADGE from './../../assets/img/clients/STYLE_BADGE.png';
import Silvergate_Academy from './../../assets/img/clients/Silvergate_Academy.png';
import digitaldesk from './../../assets/img/clients/digitaldesk.jpeg';
import mti from './../../assets/img/clients/mti.jpg';
import taibah from './../../assets/img/clients/taibah.jpg';
import aivolve from './../../assets/img/clients/aivolve.png';
import clearing from './../../assets/img/clients/clearing.JPG';

export const ourClients = [
    {
        id: 1,
        img: `${Organization}`,
        title: "Organization ",
    },
    {
        id: 2,
        img: `${Outss_files}`,
        title: "Outss_files",
    },
    {
        id: 3,
        img: `${STYLE_BADGE}`,
        title: "STYLE_BADGE",
    },
    {
        id: 4,
        img: `${Silvergate_Academy}`,
        title: "Silvergate_Academy",
    },
    {
        id: 5,
        img: `${digitaldesk}`,
        title: "digitaldesk",
    },
    {
        id: 6,
        img: `${mti}`,
        title: "mti",
    },
    {
        id: 7,
        img: `${taibah}`,
        title: "taibah",
    },
    {
        id: 8,
        img: `${aivolve}`,
        title: "aivolve",
    },
    {
        id: 9,
        img: `${clearing}`,
        title: "clearing",
    },
]