import React from "react";
import Layout from "../Layout/Layout";
import Products from "../../components/Products/Products";

const ProductsPage = () => {
  return (
    <Layout>
      <div className="section-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>Our Projects</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Products />
    </Layout>
  );
};

export default ProductsPage;
